import React from "react";

import { MDBCol, MDBIcon } from "mdbreact";

import PropTypes from "prop-types";

/**
 * 	Carousel de texto.
 * 	@param {array} bgColor - Recebe as classes de gradient sendo bgprimary-gradient, bgsecondary-gradient e bgterciary-gradient
 * 	@param {array} objetos - Recebe um array de objetos com os seguintes paramêtros
 *
 *  @param {string} text: "string",
 *  @param {string} title: "string",
 *  @param {string} description: "string"
 */
export default function Documento({ name, file, extension, bgColor }) {
	Documento.propTypes = {
		name: PropTypes.string,
		file: PropTypes.string,
		extension: PropTypes.string,
		bgColor: PropTypes.string,
	};

	return (
		<MDBCol className="d-flex flex-row pt-5">
			<a href={file} target="_blank" rel="noreferrer">
				<span className="fa-stack fa-2x">
					<MDBIcon icon="circle fa-stack-2x" size="2x" className={bgColor} />
					{extension == "pdf" && (
						<MDBIcon far icon="file-pdf text-white fa-stack-1x" />
					)}
					{(extension == "docx" ||
						extension == "word" ||
						extension == "doc") && (
						<MDBIcon far icon="file-word text-white fa-stack-1x" />
					)}
					{(extension == "xlsx" ||
						extension == "excel" ||
						extension == "xls") && (
						<MDBIcon far icon="file-excel text-white fa-stack-1x" />
					)}
				</span>
			</a>
			<div className="pl-2">
				<h5>{name}</h5>
				<a href={file} target="_blank" rel="noreferrer">
					<MDBIcon icon="download" /> Visualizar
				</a>
			</div>
		</MDBCol>
	);
}
