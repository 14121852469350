/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from "react";
import Layout from "../../Layout/MasterLayout/MasterLayout";

import Documento from "../../components/Documento/Documento";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

import imgFormaturas from "../../assets/images/a-escola/formaturas/formaturas.png";
import SEO from "../../components/SEO/SEO";

export default function Formaturas() {
	return (
		<Layout>
			<SEO title="Formaturas" lang="pt-br"/>
			<MDBContainer>
				<MDBRow>
					<MDBCol col={12} md={6} className="order-1 order-sm-0 my-auto">
						<h1 className="pageTitle fgprimary-gradient pb-3">
							formaturas
						</h1>
						<p className="text-justify">
							// a cerimônia de conclusão do Ensino Médio, é a última etapa da
							vida escolar, e trata-se de um rito de passagem para a próxima
							etapa acadêmica. Se você tem dúvida em relação ao processo, abaixo
							temos uma apresentação indicando tudo o que contempla e se faz
							necessário para que a sua formatura ocorra.
						</p>
					</MDBCol>
					<MDBCol
						col={12}
						md={6}
						className="py-5 text-justify order-0 order-sm-1"
					>
						<img src={imgFormaturas} className="img-fluid" />
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<Documento
						name="Produtoras"
						file="https://www.feevale.br/Comum/midias/5c4055eb-2fa9-4827-b509-565323f472e4/RELACAO%20PRODUTORAS.pdf"
						extension="pdf"
						bgColor="fgprimary-gradient"
					/>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
